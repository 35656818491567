:root {
  --accent: #489bdc;
}

* {
  box-sizing: border-box;
}

html {
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: Inter var, sans-serif;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.fixed-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#container {
  width: 100%;
  height: 100%;
  transition: all 10s;
  position: absolute;
  top: 0;
  left: 0;
}

#container.animate-br {
  translate: 20px 20px;
}

#container.animate-tr {
  translate: 20px -20px;
}

#container.animate-tl {
  translate: -20px -20px;
}

#container.animate-bl {
  translate: -20px 20px;
}

#container svg {
  color: rgba(170, 170, 170, .267);
  transition: all .5s;
}

#container svg rect {
  fill: currentColor;
}

#container .wrapper {
  background-color: rgba(0, 0, 0, 0);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
  transform: rotate(45deg);
}

#container .wrapper:hover svg {
  color: var(--accent);
  transform: scale(4);
}

main {
  flex-direction: column;
  height: 100%;
  padding: 1rem 2rem;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

main > h1 {
  flex-direction: row;
  align-items: center;
  margin: 0;
  display: flex;
}

main > h1 > img {
  width: 4rem;
}

main > h2 {
  padding-left: 4rem;
  font-weight: 300;
}

main > footer {
  margin-top: auto;
  font-size: .8em;
}

h2#projects {
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: -4rem;
  font-weight: bold;
}

h2#projects:before {
  content: "";
  border-left: .5rem solid var(--accent);
  margin-right: .5rem;
}

.projects {
  flex-flow: wrap;
  display: flex;
}

.projects > * {
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.project {
  border: 4px solid var(--accent);
  max-width: 500px;
  padding: 1rem 2rem;
  box-shadow: 4px 4px #000;
}

.project a {
  letter-spacing: 1.2px;
  font-family: sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: none;
}

.project .title {
  font-weight: bold;
}

/*# sourceMappingURL=index.4fae8839.css.map */
