:root {
  --accent: rgb(72, 155, 220);
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
}

.fixed-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

#container {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 10s ease;

  left: 0;
  top: 0;
}

#container.animate-br {
  /* left: 20px;
  top: 20px; */
  translate: 20px 20px;
}
#container.animate-tr {
  /* left: 20px;
  top: -20px; */
  translate: 20px -20px;
}
#container.animate-tl {
  /* left: -20px;
  top: -20px; */
  translate: -20px -20px;
}
#container.animate-bl {
  /* left: -20px;
  top: 20px; */
  translate: -20px 20px;
}

#container svg {
  color: #aaa4;
  transition: all 0.5s ease;
}

#container svg rect {
  fill: currentColor;
}

#container .wrapper {
  width: 32px;
  height: 32px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}

#container .wrapper:hover svg {
  color: var(--accent);
  transform: scale(4);
}

main {
  position: fixed;
  overflow: auto;
  inset: 0;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

main > h1 {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

main > h1 > img {
  width: 4rem;
}

main > h2 {
  font-weight: 300;
  padding-left: 4rem;
}

main > footer {
  margin-top: auto;
  font-size: 0.8em;
}

h2#projects {
  text-transform: uppercase;
  font-weight: bold;
  margin-left: -4rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

h2#projects::before {
  content: '';
  border-left: 0.5rem solid var(--accent);
  margin-right: 0.5rem;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.projects > * {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.project {
  padding: 1rem 2rem;

  border: 4px solid var(--accent);
  box-shadow: 4px 4px 0 black;
  max-width: 500px;
}

.project a {
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 1.2;
  font-family: sans-serif;
}

.project .title {
  font-weight: bold;
}
